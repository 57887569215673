html {
  box-sizing: border-box;
  // scroll-behavior: smooth;

}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: calc(26/ 14);
  letter-spacing: 0.02rem;
  color: $primary-color;
  background-color: #ffffff;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

body.modal-open {
  overflow: hidden;
}

/* --------------------------------------------- common classes ------------------------------------------------------- */
.section {
  margin: 0;
  padding: 10px 0;
  width: 100%;

  @media screen and (min-width:$mobile) {}

  @media screen and (min-width: $tablet) {}

  @media screen and (min-width: $desktop) {}
}

.container {
  max-width: 100%;
  display: block;
  text-align: center;
  padding: 0 15px;
  margin: 0 auto;

  @media screen and (min-width: $mobile) {
    // max-width: $mobile;
  }

  @media screen and (min-width: $tablet) {
    // max-width: $tablet;
  }

  @media screen and (min-width: $desktop) {
    max-width: 100%;
    padding: 0 30px;
  }
}

.list {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
.item,
.link,
.text,
.img {
  padding: 0;
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.display-none {
  display: none;
}