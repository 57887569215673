@import 'src/styles/main.scss';
@import './heroTitles';

.hero {
  display: block;
  text-align: center;
  margin: 0 auto;
  // height: 90vh;
  // width: 100vw;
  // width: 100%;
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  // background-position: center;
  // background-size: cover;
  // outline: 3px red solid;
  // background-image:
  //   linear-gradient(to right,
  //     rgba(50, 50, 50, 0.2),
  //     rgba(50, 50, 50, 0.2)),
  //   url(../../../images/hero-background1.jpg),
}

.heroTextSection {
  display: block;
  margin: auto;
  text-align: center;
  padding-bottom: 80px;
}

.statement {
  display: block;
  margin: 50px auto 0;
  align-self: end;
  // text-align: end;
  max-width: 1100px;
  color: "";
}

.comment {
  display: block;
  margin: auto;
  text-align: end;
  font-weight: 600;
}

.logo {
  display: block;
  margin: auto;
  width: 20%;
}

.logoImg {
  width: auto;
  height: auto;
  margin: auto;
  scale: 0.6;
}


@media screen and (min-width:$mobile) {

  .hero {
    // height: 940px;
    height: 90vh;

  }

}


@media screen and (min-width:$tablet) {

  .hero {
    // height: 940px;
    height: 90vh;

  }

}


// @media screen and (min-width:$desktop) {

//   .hero {
//     // height: 940px;
//     height: 90vh;

//   }

// }