@import 'src/styles/main';

.benefits {
  background-color: $bgc-primary;
  // background: transparent;
}

.itemContainer {
  // position: relative;
  display: block;
  width: 100%;
  margin: 20px auto;
  text-align: center;
  color: $primary-color;

}

.benefitsTitle {
  // color: green;
  margin-top: 20px;
}


.benefitsSubtitle {
  display: block;
  text-align: center;
  margin: auto;
  // color: green;
  font-size: 14px;
}



.contentContainer {
  position: relative;
  display: block;
  width: 100%;
  // color: yellow;
}

.listContainer {
  width: 90%;
  margin: 0;
  font-size: 12px;

}

.imgContainer {
  // position: relative;
  position: absolute;
  // max-width: 400px;
  // height: 250px;
  z-index: -3;
}

.benefitsBackdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.textContainer {
  width: 100%;
}

// .benefitsImage{
//   width: 100%;
//   height: 100%;
//   z-index: -4;
// }




@media screen and (min-width:$mobile) {
  // .textContainer {
  //   width: 100%;
  // }
}

@media screen and (min-width:$tablet) {

  .benefits {
    // background-color: rgba(143, 143, 65, 0.4);
  }

  .benefitsSubtitle {
    font-size: 16px;
    max-width: 1200px;
  }


  // .contentConteiner{
  //   display: flex;
  //     justify-content: space-around;  
  //     // color: green;
  // }

  .itemContainer {
    display: flex;
    justify-content: space-around;
    // align-items: center;
    align-items: top;
  }

  .benefitsBackdrop {
    display: none;
  }

  // .benefitsImage{
  //   z-index: 0;
  //   width: 100%;
  // }

  .imgContainer {
    position: static;
    display: block;
    z-index: 0;
    // max-width: 600px;
    width: 600px;
    height: 500px;
    // outline: 1px solid red;
  }
}

.listContainer {
  display: block;
  font-size: 16px;
  text-align: start;
}


.textContainer {
  // width: 50%;
  margin: 20px;
}



@media screen and (min-width:$desktop) {
  .contentContainer {}

  .benefitsSubtitle {
    font-size: 20px;
  }

  .listContainer {
    font-size: 18px;
  }

  .textContainer {
    width: 50%;
  }

}