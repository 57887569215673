.contactsAnimation {
  animation: animate100 1000ms $timing-function forwards;
}

.contentAnimation {
  animation: animate200 1200ms $timing-function forwards;
}

.animatoinActive {
  animation: animate200 800ms $timing-function forwards;
}

.portfolioAnimation {
  animation: animate400 1000ms $timing-function forwards;
}


// --------------------------------------

@keyframes animate100 {
  0% {
    opacity: 0;
    // transform: translate(20%, 50%);
    // transform: translateX(50%);
    transform: translateY(200%);
  }

  30% {
    opacity: 0;
    // transform: translate(20%, 50%);
    // transform: translateX(20%);
    transform: translateY(200%);
  }

  100% {
    opacity: 1;
    transform: translate(0);
    // transform: translateX(0);
    // transform: translateY(0);
  }
}

@keyframes animate150 {
  0% {
    opacity: 0;
    // transform: translate(20%, 50%);
    transform: translateX(20%);
    // transform: translateY(20%);
  }

  30% {
    opacity: 0;
    // transform: translate(20%, 50%);
    transform: translateX(20%);
    // transform: translateY(20%);
  }

  100% {
    opacity: 1;
    transform: translate(0);
    // transform: translateX(0);
    // transform: translateY(0);
  }
}



@keyframes animate200 {
  0% {
    opacity: 0;
    // transform: translateX(20%);
  }

  30% {
    opacity: 0;
    // transform: translateX(20%);
  }

  100% {
    opacity: 1;
    // transform: translate(0);
  }
}

@keyframes animate300 {
  0% {
    transform: scale(0);
  }

  30% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}


@keyframes animate400 {
  0% {
    opacity: 0;
    // transform: translate(20%, 50%);
    // transform: translateX(50%);
    transform: translateY(20%);
  }

  30% {
    opacity: 0;
    // transform: translate(20%, 50%);
    // transform: translateX(20%);
    transform: translateY(20%);
  }

  100% {
    opacity: 1;
    transform: translate(0);
    // transform: translateX(0);
    // transform: translateY(0);
  }
}



.animationTitle,
.animationItems {
  opacity: 0;
}


// .appear{
//   // transform: translateX(-100%);
//   opacity: 0;
// }

// .appearActive{
//   // transform: translateX(0);
//   opacity: 1;
//   // transition: all  3000ms $timing-function;
//   transition: all  1500ms linear;
// }